import { Box } from '@mui/material';
import { TMyListingsTab } from '@typescript/TTabs';
import { MyListingsContentType } from '@constants/CContentType';
import { NO_LISTINGS_MS } from '@constants/CDefaultMessages';
import DataWrapper from '@components/shared/DataWrapper';
import { ListingAction, OfferAction } from '@constants/CDataActions';
import { TListing } from '@typescript/models/Listing.model';
import { TOffer } from '@typescript/models/Offer.model';
import { useState } from 'react';
import { useGetMyActiveListingsQuery } from '@store/api/listingApi';
import { paginationZod } from '@typescript/dtos/shared/pagination.dto';
import InactiveListings from './InactiveListings';
import ActiveListings from './ActiveListings';

interface Props {
  tab: TMyListingsTab;
  handleListingActions: (
    listing: TListing,
    listingAction: ListingAction,
  ) => void;
  handleOfferActions: (
    listing: TListing,
    offerAction: OfferAction,
    offer: TOffer,
  ) => void;
}

function MyListingsTable({
  tab,
  handleListingActions,
  handleOfferActions,
}: Props) {
  const [page, setPage] = useState(0);

  const [pageSize, setPageSize] = useState(5);

  const {
    data: { resource: activeListings, count: countActiveListings } = {
      resource: [],
      count: 0,
    },
    isLoading: isLoadingActive,
    isFetching: isFetchingActive,
  } = useGetMyActiveListingsQuery(
    paginationZod.parse({ page: page + 1, pageSize }),
  );

  const tableContent = () => {
    switch (tab.index) {
      case MyListingsContentType.INACTIVE: {
        return <InactiveListings />;
      }

      default: {
        return (
          <DataWrapper
            data={activeListings}
            isLoading={isLoadingActive}
            defaultOptions={{
              fullHeight: true,
              text: NO_LISTINGS_MS,
            }}
            isLoadingOptions={{
              dimension: 'contained',
              sx: {
                height: '100%',
              },
            }}
          >
            <ActiveListings
              count={countActiveListings}
              isFetching={isFetchingActive}
              pageSize={pageSize}
              setCurrentPage={setPage}
              setPageSize={setPageSize}
              currentPage={page}
              content={activeListings}
              handleListingActions={handleListingActions}
              handleOfferActions={handleOfferActions}
            />
          </DataWrapper>
        );
      }
    }
  };

  return <Box sx={{ height: '90%', mt: '0.5rem' }}>{tableContent()}</Box>;
}

export default MyListingsTable;
