import { MyListingsContentType } from '@constants/CContentType';
import { TMyListingsTab } from '@typescript/TTabs';
import { Box } from '@mui/material';
import { NO_LISTINGS_MS } from '@constants/CDefaultMessages';
import DataWrapper from '@components/shared/DataWrapper';
import { ListingAction, OfferAction } from '@constants/CDataActions';
import { TListing } from '@typescript/models/Listing.model';
import { TOffer } from '@typescript/models/Offer.model';
import { useState } from 'react';
import {
  useGetMyInactiveListingsQuery,
  useGetMyActiveListingsQuery,
} from '@store/api/listingApi';
import { paginationZod } from '@typescript/dtos/shared/pagination.dto';
import ActiveListingsResponsive from './ActiveListingsResponsive';
import InactiveListingsResponsive from './InactiveListingsResponsive';

interface Props {
  tab: TMyListingsTab;
  handleListingActions: (
    listing: TListing,
    listingAction: ListingAction,
  ) => void;
  handleOfferActions: (
    listing: TListing,
    offerAction: OfferAction,
    offer: TOffer,
  ) => void;
}

function MyListingsTableResponsive({
  tab,
  handleListingActions,
  handleOfferActions,
}: Props) {
  const [page, setPage] = useState(1);

  const [pageSize, setPageSize] = useState(5);

  const {
    data: { resource: inactiveListings, count: countInactiveListings } = {
      resource: [],
      count: 0,
    },
    isLoading: isLoadingInactive,
    isFetching: isFetchingInactive,
  } = useGetMyInactiveListingsQuery(paginationZod.parse({ page, pageSize }));

  const {
    data: { resource: activeListings, count: countActiveListings } = {
      resource: [],
      count: 0,
    },
    isLoading: isLoadingActive,
    isFetching: isFetchingActive,
  } = useGetMyActiveListingsQuery(paginationZod.parse({ page, pageSize }));

  const tableContentResponsive = () => {
    switch (tab.index) {
      case MyListingsContentType.INACTIVE: {
        return (
          <DataWrapper
            data={inactiveListings}
            isLoading={isLoadingInactive}
            defaultOptions={{
              fullHeight: true,
              text: NO_LISTINGS_MS,
            }}
          >
            <InactiveListingsResponsive
              content={inactiveListings}
              isLoading={isLoadingInactive}
              actionSeeMore={() => {
                setPage(1);
                setPageSize((prev) => prev + 10);
              }}
              count={countInactiveListings}
              isFetching={isFetchingInactive}
            />
          </DataWrapper>
        );
      }

      default: {
        return (
          <DataWrapper
            data={activeListings}
            isLoading={isLoadingActive}
            defaultOptions={{
              fullHeight: true,
              text: NO_LISTINGS_MS,
            }}
            isLoadingOptions={{
              dimension: 'contained',
            }}
          >
            <ActiveListingsResponsive
              content={activeListings}
              handleListingActions={handleListingActions}
              handleOfferActions={handleOfferActions}
              isLoading={isLoadingActive}
              actionSeeMore={() => {
                setPage(1);
                setPageSize((prev) => prev + 10);
              }}
              count={countActiveListings}
              isFetching={isFetchingActive}
            />
          </DataWrapper>
        );
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gap: '0.5rem',
        maxWidth: { xs: '450px', md: 'unset' },
        marginInline: 'auto',
        mt: '0.5rem',
        height: '100%',
      }}
    >
      {tableContentResponsive()}
    </Box>
  );
}

export default MyListingsTableResponsive;
