import CardWrapper from '@components/shared/CardWrapper';

import {
  askingPriceCell,
  currentTokenPriceCell,
  listingTypeCell,
  negotiableCell,
  tokenAmountCell,
  totalCostCell,
} from '@components/tables-columns/TableCell';
import { ListingAction } from '@constants/CDataActions';
import useStyles from '@hooks/useStyles';
import { Box, CircularProgress, Typography } from '@mui/material';
import { BuyGradientDark } from '@styles/shared/SButtons';
import { TListing } from '@typescript/models/Listing.model';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useListings from '@hooks/useListings';
import ModifyExpirationDate from '@components/modals/listings/ModifyExpirationDate';
import { idZod } from '@typescript/dtos/shared/id.dto';
import DeleteListingModal from '@components/modals/listings/DeleteListingModal';
import { GetInactiveListingActions } from '@components/tables-columns/ListingsColumns';
import ClaimExpiredListingTokensModal from '@components/modals/listings/ClaimExpiredListingTokens';

interface Props {
  content: TListing[];
  actionSeeMore: () => void;
  count: number;
  isLoading: boolean;
  isFetching: boolean;
}

function InactiveListingsResponsive({
  content,
  actionSeeMore,
  count,
  isLoading,
  isFetching,
}: Props) {
  const { border1pxSecondaryLight } = useStyles();
  const navigate = useNavigate();
  const { deleteListing } = useListings();
  const [action, setAction] = useState<ListingAction>();

  const [openActionModal, setOpenActionModal] = useState<boolean>(false);
  const [currentListing, setCurrentListing] = useState<TListing | null>(null);

  const closeActionModal = () => {
    setOpenActionModal(false);
  };

  const handleListingActions = useCallback(
    async (listing: TListing, listingAction: ListingAction) => {
      setCurrentListing(listing);
      setAction(listingAction);
      switch (listingAction) {
        case ListingAction.CLAIM_EXPIRED_LISTING_TOKENS:
          setOpenActionModal(true);
          break;
        case ListingAction.UPDATE_LISTING_EXPIRATION:
          setOpenActionModal(true);
          break;
        case ListingAction.DELETE:
          setOpenActionModal(true);
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const listingModals = () => {
    if (currentListing) {
      if (action === ListingAction.DELETE) {
        return (
          <DeleteListingModal
            openModal={openActionModal}
            closeModal={closeActionModal}
            listing={currentListing}
            deleteListing={async (payload) => {
              await deleteListing(idZod.parse(payload));
            }}
          />
        );
      }
      if (action === ListingAction.UPDATE_LISTING_EXPIRATION)
        return (
          <ModifyExpirationDate
            listing={currentListing}
            openModal={openActionModal}
            closeModal={closeActionModal}
          />
        );
      if (action === ListingAction.CLAIM_EXPIRED_LISTING_TOKENS)
        return (
          <ClaimExpiredListingTokensModal
            openModal={openActionModal}
            closeModal={closeActionModal}
            listing={currentListing}
          />
        );
    }
    return null;
  };

  return (
    <Box sx={{ pb: '5rem' }}>
      <Box sx={{ display: 'grid', gap: '0.5rem', mb: '1rem' }}>
        {content.map((listing) => (
          <CardWrapper isFetching={isFetching} key={listing.id}>
            <Box
              key={listing.id}
              sx={{
                width: '100%',
                borderRadius: '6px',
                border: border1pxSecondaryLight,
                backgroundColor: 'secondary.main',
              }}
            >
              <Box
                sx={{
                  borderBottom: border1pxSecondaryLight,
                  borderRadius: '4px 4px 0 0',
                  py: 2,
                  px: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <img
                    src={listing.project.image}
                    style={{ borderRadius: '6px', width: 48, height: 48 }}
                    alt=""
                  />
                  <Typography
                    sx={{
                      pl: 1,
                      fontWeight: 400,
                      fontSize: '17px',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      ':hover': {
                        textDecoration: 'none',
                      },
                    }}
                    onClick={() =>
                      navigate(`/projects/${listing.project.slug}`)
                    }
                  >
                    {listing.project.name}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: { xs: 'grid', md: 'flex' },
                  gridTemplateColumns: { xs: '1fr 1fr' },
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: border1pxSecondaryLight,
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Event
                  </Typography>
                  {listingTypeCell(listing).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: { md: border1pxSecondaryLight },
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Current Token Price
                  </Typography>
                  <Box sx={{ gridColumn: 'span 2' }}>
                    {
                      currentTokenPriceCell(listing.project.currentPrice)
                        .renderCell
                    }
                  </Box>
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: border1pxSecondaryLight,
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Asking Price
                  </Typography>
                  {askingPriceCell(listing).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: { xs: 'none', md: border1pxSecondaryLight },
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Token Amount
                  </Typography>
                  {tokenAmountCell(listing.amount).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: border1pxSecondaryLight,
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Total Cost
                  </Typography>
                  {totalCostCell(listing.price).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: { xs: 'none', md: border1pxSecondaryLight },
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Negotiable
                  </Typography>
                  {negotiableCell(listing).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    flex: 1,
                    gridColumn: 'span 2',
                  }}
                >
                  <GetInactiveListingActions
                    listing={listing}
                    handleListingActions={handleListingActions}
                  />
                </Box>
              </Box>
            </Box>
          </CardWrapper>
        ))}
      </Box>
      {listingModals()}
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            display: count === content.length ? 'none' : 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            <BuyGradientDark onClick={actionSeeMore}>See More</BuyGradientDark>
          </Box>
        </Box>
      )}
    </Box>
  );
}
export default InactiveListingsResponsive;
