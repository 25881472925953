import DataGridWrapper from '@components/shared/DataGridWrapper';
import { getListingsColumnsWithAllActions } from '@components/tables-columns/ListingsColumns';
import { ListingAction, OfferAction } from '@constants/CDataActions';
import useUser from '@hooks/useUser';
import { useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DataGridCustomStyles } from '@styles/shared/SDataGrid';
import { TListing } from '@typescript/models/Listing.model';
import { TOffer } from '@typescript/models/Offer.model';
import { memo } from 'react';

interface Props {
  content: TListing[];
  handleListingActions: (
    listing: TListing,
    listingAction: ListingAction,
  ) => void;
  handleOfferActions: (
    listing: TListing,
    offerAction: OfferAction,
    offer: TOffer,
  ) => void;
  pageSize: number;
  count: number;
  currentPage: number;
  setCurrentPage: (data: number) => void;
  setPageSize: (data: number) => void;
  isFetching: boolean;
}

function ActiveListings({
  content,
  handleListingActions,
  handleOfferActions,
  currentPage: page,
  isFetching,
  pageSize,
  setCurrentPage,
  setPageSize,
  count,
}: Props) {
  const theme = useTheme();

  const { currentUser: user } = useUser();

  const DataGridRemovedScroll = {
    ...DataGridCustomStyles,
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: 'calc(100vh - 340px)',
      overflow: 'auto!important',
      paddingBottom: '2px',
      [theme.breakpoints.up('xl')]: {
        maxHeight: 'calc(100vh - 340px)',
        overflowX: 'hidden !important',
        overflowY: 'auto !important',
      },
    },
  };
  return (
    <DataGridWrapper isFetching={isFetching}>
      <DataGrid
        sx={DataGridRemovedScroll}
        rows={content}
        columns={getListingsColumnsWithAllActions(
          handleListingActions,
          handleOfferActions,
          user,
        )}
        autoHeight
        disableSelectionOnClick
        pagination
        initialState={{
          pagination: { pageSize, page },
        }}
        getRowId={(row) => row.id}
        paginationMode="server"
        rowCount={count}
        onPageChange={(newPage) => setCurrentPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        disableColumnSelector
        disableDensitySelector
        disableColumnFilter
      />
    </DataGridWrapper>
  );
}

export default memo(ActiveListings);
