import DataGridWrapper from '@components/shared/DataGridWrapper';
import DataWrapper from '@components/shared/DataWrapper';
import { inactiveListingActions } from '@components/tables-columns/ListingsColumns';
import { NO_LISTINGS_MS } from '@constants/CDefaultMessages';
import { Box, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useGetMyInactiveListingsQuery } from '@store/api/listingApi';
import { DataGridCustomStyles } from '@styles/shared/SDataGrid';
import { paginationZod } from '@typescript/dtos/shared/pagination.dto';
import { memo, useCallback, useState } from 'react';
import { ListingAction } from '@constants/CDataActions';
import { TListing } from '@typescript/models/Listing.model';
import ModifyExpirationDate from '@components/modals/listings/ModifyExpirationDate';
import useListings from '@hooks/useListings';
import { idZod } from '@typescript/dtos/shared/id.dto';
import DeleteListingModal from '@components/modals/listings/DeleteListingModal';
import { useMoralis } from 'react-moralis';
import ClaimExpiredListingTokensModal from '@components/modals/listings/ClaimExpiredListingTokens';
import useActionOrConnect from '@hooks/useActionOrConnect';

function InactiveListings() {
  const theme = useTheme();
  const [page, setPage] = useState<number>(0);
  const { deleteListing } = useListings();
  const [pageSize, setPageSize] = useState<number>(5);
  const handleActionOrConnect = useActionOrConnect();
  const { isWeb3Enabled } = useMoralis();

  const {
    data: { resource: inactiveListings, count } = {
      resource: [],
      count: 0,
    },
    isLoading,
    isFetching,
  } = useGetMyInactiveListingsQuery(
    paginationZod.parse({ page: page + 1, pageSize }),
  );

  const [openActionModal, setOpenActionModal] = useState<boolean>(false);

  const closeActionModal = () => {
    setOpenActionModal(false);
  };
  const [action, setAction] = useState<ListingAction>();

  const [currentListing, setCurrentListing] = useState<TListing>();

  const handleListingActions = useCallback(
    async (listing: TListing, listingAction: ListingAction) => {
      if (!isWeb3Enabled) {
        handleActionOrConnect();
      } else {
        setCurrentListing(listing);
        setAction(listingAction);
        switch (listingAction) {
          case ListingAction.CLAIM_EXPIRED_LISTING_TOKENS:
            setOpenActionModal(true);
            break;
          case ListingAction.UPDATE_LISTING_EXPIRATION:
            setOpenActionModal(true);
            break;
          case ListingAction.DELETE:
            setOpenActionModal(true);
            break;
          default:
            break;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const DataGridRemovedScroll = {
    ...DataGridCustomStyles,
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: 'calc(100vh - 340px)',
      overflow: 'auto!important',
      paddingBottom: '2px',
      [theme.breakpoints.up('xl')]: {
        maxHeight: 'calc(100vh - 340px)',
        overflowX: 'hidden !important',
        overflowY: 'auto !important',
      },
    },
  };

  const listingModals = () => {
    if (currentListing) {
      if (action === ListingAction.DELETE) {
        return (
          <DeleteListingModal
            openModal={openActionModal}
            closeModal={closeActionModal}
            listing={currentListing}
            deleteListing={async (payload) => {
              await deleteListing(idZod.parse(payload));
            }}
          />
        );
      }
      if (action === ListingAction.UPDATE_LISTING_EXPIRATION)
        return (
          <ModifyExpirationDate
            listing={currentListing}
            openModal={openActionModal}
            closeModal={closeActionModal}
          />
        );
      if (action === ListingAction.CLAIM_EXPIRED_LISTING_TOKENS)
        return (
          <ClaimExpiredListingTokensModal
            openModal={openActionModal}
            closeModal={closeActionModal}
            listing={currentListing}
          />
        );
    }
    return null;
  };

  return (
    <Box>
      <DataWrapper
        data={inactiveListings}
        isLoading={isLoading}
        defaultOptions={{
          fullHeight: true,
          text: NO_LISTINGS_MS,
        }}
        isLoadingOptions={{
          dimension: 'contained',
          sx: {
            height: '100%',
          },
        }}
      >
        <DataGridWrapper isFetching={isFetching}>
          <DataGrid
            paginationMode="server"
            sx={DataGridRemovedScroll}
            rows={inactiveListings}
            columns={inactiveListingActions(handleListingActions)}
            autoHeight
            loading={isLoading || isFetching}
            disableSelectionOnClick
            rowCount={count}
            initialState={{
              pagination: { pageSize, page },
            }}
            pagination
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            disableColumnSelector
            disableDensitySelector
            disableColumnFilter
          />
        </DataGridWrapper>
      </DataWrapper>

      {listingModals()}
    </Box>
  );
}

export default memo(InactiveListings);
