import { ListingAction, OfferAction } from '@constants/CDataActions';
import useStyles from '@hooks/useStyles';
import { Box, CircularProgress, Typography } from '@mui/material';
import { TListing } from '@typescript/models/Listing.model';
import { TOffer } from '@typescript/models/Offer.model';
import { useNavigate } from 'react-router-dom';
import { BuyGradientDark } from '@styles/shared/SButtons';
import {
  ListingStatusCell,
  askingPriceCell,
  currentTokenPriceCell,
  expireInCell,
  listingTypeCell,
  negotiableCell,
  offeredPriceCell,
  tokenAmountCell,
  totalCostCell,
  totalOfferCostCell,
} from '@components/tables-columns/TableCell';

import useUser from '@hooks/useUser';
import { getAllListingsActions } from '@components/tables-columns/ListingsColumns';
import CardWrapper from '@components/shared/CardWrapper';

interface Props {
  content: TListing[];
  handleListingActions: (
    listing: TListing,
    listingAction: ListingAction,
  ) => void;
  handleOfferActions: (
    listing: TListing,
    offerAction: OfferAction,
    offer: TOffer,
  ) => void;
  actionSeeMore: () => void;
  count: number;
  isLoading: boolean;
  isFetching: boolean;
}

function ActiveListingsResponsive({
  content,
  handleListingActions,
  handleOfferActions,
  actionSeeMore,
  count,
  isLoading,
  isFetching,
}: Props) {
  const { border1pxSecondaryLight } = useStyles();

  const navigate = useNavigate();

  const { currentUser: user } = useUser();

  return (
    <Box sx={{ pb: '5rem' }}>
      <Box sx={{ mb: '1rem', display: 'grid', gap: '0.5rem' }}>
        {content.map((listing) => (
          <CardWrapper isFetching={isFetching} key={listing.id}>
            <Box
              sx={{
                width: '100%',
                borderRadius: '6px',
                border: border1pxSecondaryLight,
                backgroundColor: 'secondary.main',
              }}
            >
              <Box
                sx={{
                  borderBottom: border1pxSecondaryLight,
                  borderRadius: '4px 4px 0 0',
                  py: 2,
                  px: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <img
                    src={listing.project.image}
                    style={{ borderRadius: '6px', width: 48, height: 48 }}
                    alt=""
                  />
                  <Typography
                    sx={{
                      pl: 1,
                      fontWeight: 400,
                      fontSize: '17px',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      ':hover': {
                        textDecoration: 'none',
                      },
                    }}
                    onClick={() =>
                      navigate(`/projects/${listing.project.slug}`)
                    }
                  >
                    {listing.project.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    gap: '1rem',
                    gridTemplateColumns: '1fr 1fr',
                    minWidth: '300px',
                    display: { xs: 'none', md: 'flex' },
                  }}
                >
                  {getAllListingsActions(
                    listing,
                    handleListingActions,
                    handleOfferActions,
                    user,
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: { xs: 'grid', md: 'flex' },
                  gridTemplateColumns: { xs: '1fr 1fr' },
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: border1pxSecondaryLight,
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Event
                  </Typography>
                  {listingTypeCell(listing).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: { md: border1pxSecondaryLight },
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Current Token Price
                  </Typography>
                  {
                    currentTokenPriceCell(listing.project.currentPrice)
                      .renderCell
                  }
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: border1pxSecondaryLight,
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Asking Price
                  </Typography>
                  {askingPriceCell(listing).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: { xs: 'none', md: border1pxSecondaryLight },
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Token Amount
                  </Typography>
                  {tokenAmountCell(listing.amount).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: border1pxSecondaryLight,
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Total Cost
                  </Typography>
                  {totalCostCell(listing.price).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: { xs: 'none', md: border1pxSecondaryLight },
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Offer Price
                  </Typography>
                  {offeredPriceCell(listing).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: border1pxSecondaryLight,
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Total Offer Cost
                  </Typography>
                  {totalOfferCostCell(listing).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: { xs: 'none', md: border1pxSecondaryLight },
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Negotiable
                  </Typography>
                  {negotiableCell(listing).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderRight: border1pxSecondaryLight,
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Expire In
                  </Typography>
                  {expireInCell(listing).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    flex: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      opacity: 0.5,
                      mb: 1,
                    }}
                  >
                    Status
                  </Typography>
                  <ListingStatusCell listing={listing} />
                </Box>
              </Box>
              <Box
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  gap: '1rem',
                  borderTop: border1pxSecondaryLight,
                  py: 2,
                  px: 2,
                }}
              >
                {getAllListingsActions(
                  listing,
                  handleListingActions,
                  handleOfferActions,
                  user,
                )}
              </Box>
            </Box>
          </CardWrapper>
        ))}
      </Box>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            display: count === content.length ? 'none' : 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            <BuyGradientDark onClick={actionSeeMore}>See More</BuyGradientDark>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default ActiveListingsResponsive;
